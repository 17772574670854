// Type d’objet

/** Canal de distribution */
export const TRANSLATION_OBJECT_TYPE_VALUE_CHANNEL = "channel";
/** Emplacement de contenu */
export const TRANSLATION_OBJECT_TYPE_VALUE_CONTENT_SECTION = "content_section";
/** Hôtel */
export const TRANSLATION_OBJECT_TYPE_VALUE_HOTEL = "hotel";
/** Chambre */
export const TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_ROOM = "hotel_room";
/** Service supplémentaire d’hébergement */
export const TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_SERVICE = "hotel_service";
/** Fonctionnalités d’hébergement */
export const TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_FEATURE = "hotel_feature";
/** Photo d’hébergement */
export const TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_PICTURE = "hotel_picture";
/** Diaporama */
export const TRANSLATION_OBJECT_TYPE_VALUE_SLIDE = "slide";
/** Assurance */
export const TRANSLATION_OBJECT_TYPE_VALUE_CHANNEL_INSURANCE =
  "channel_insurance";
/** Page administrable */
export const TRANSLATION_OBJECT_TYPE_VALUE_CONTENT = "content";
/** Client */
export const TRANSLATION_OBJECT_TYPE_VALUE_CUSTOMER = "customer";
/** Publication */
export const TRANSLATION_OBJECT_TYPE_VALUE_POST = "post";
/** Catégorie de publication */
export const TRANSLATION_OBJECT_TYPE_VALUE_POST_CATEGORY = "post_category";
/** Photo de couverture */
export const TRANSLATION_OBJECT_TYPE_VALUE_COVER = "cover";

export const TRANSLATION_OBJECT_TYPES = {
  [TRANSLATION_OBJECT_TYPE_VALUE_CHANNEL]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_CHANNEL,
    label: "agence",
    labelWithPreposition: "de l’agence",
    translatableAttributeGroups: {
      generalWebsiteInfo: {
        id: "generalWebsiteInfo",
        label: "Configurations générales du site web",
      },
      legalInfo: {
        id: "legalInfo",
        label: "Mentions légales du site web",
      },
      defaultSeo: {
        id: "defaultSeo",
        label: "SEO : Référencement par défaut",
      },
      homeSeo: {
        id: "homeSeo",
        label: "SEO : Page d’accueil",
      },
      contactSeo: {
        id: "contactSeo",
        label: "SEO : Page de contact",
      },
      searchSeo: {
        id: "searchSeo",
        label: "SEO : Page de recherche",
      },
      dealSeo: {
        id: "dealSeo",
        label: "SEO : Liste des bons plans (campagnes)",
      },
      citySeo: {
        id: "citySeo",
        label: "SEO : Liste des destinations phares (campagnes)",
      },
      thematicSeo: {
        id: "thematicSeo",
        label: "SEO : Liste des séjours thématiques (campagnes)",
      },
      contentSeo: {
        id: "contentSeo",
        label: "SEO : Détail d’une page administrable",
      },
      bookingSeo: {
        id: "bookingSeo",
        label: "SEO : Page de réservation",
      },
      accountSeo: {
        id: "accountSeo",
        label: "SEO : Espace privé",
      },
      roomSeo: {
        id: "roomSeo",
        label: "SEO : Page de détail d’une annonce",
      },
    },
    translatableAttributes: {
      websiteTitle: {
        id: "websiteTitle",
        label: "baseline de la page d’accueil",
        group: "generalWebsiteInfo",
        componentOptions: {
          type: "Input",
        },
      },
      websiteDesc: {
        id: "websiteDesc",
        label: "description de la page d’accueil",
        group: "generalWebsiteInfo",
        componentOptions: {
          type: "Input",
        },
      },
      legals: {
        id: "legals",
        label: "horaires de permanence téléphonique",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      legalsMail: {
        id: "legalsMail",
        label: "mentions légales des e-mails",
        group: "legalInfo",
        componentOptions: {
          type: "Input",
        },
      },
      associationLegalsPdf: {
        id: "associationLegalsPdf",
        label: "mentions légales des PDF de l’agence",
        group: "legalInfo",
        componentOptions: {
          type: "Input",
        },
      },
      legalsPdf: {
        id: "legalsPdf",
        label: "mentions légales des PDF",
        group: "legalInfo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.default.title": {
        id: "seoConfig.default.title",
        label: "titre de la page",
        group: "defaultSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.default.desc": {
        id: "seoConfig.default.desc",
        label: "description de la page",
        group: "defaultSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.home.title": {
        id: "seoConfig.home.title",
        label: "titre de la page",
        group: "homeSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.home.desc": {
        id: "seoConfig.home.desc",
        label: "description de la page",
        group: "homeSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.contact.title": {
        id: "seoConfig.contact.title",
        label: "titre de la page",
        group: "contactSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.contact.desc": {
        id: "seoConfig.contact.desc",
        label: "description de la page",
        group: "contactSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.search.title": {
        id: "seoConfig.search.title",
        label: "titre de la page",
        group: "searchSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.search.desc": {
        id: "seoConfig.search.desc",
        label: "description de la page",
        group: "searchSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.deal.title": {
        id: "seoConfig.deal.title",
        label: "titre de la page",
        group: "dealSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.deal.desc": {
        id: "seoConfig.deal.desc",
        label: "description de la page",
        group: "dealSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.city.title": {
        id: "seoConfig.city.title",
        label: "titre de la page",
        group: "citySeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.city.desc": {
        id: "seoConfig.city.desc",
        label: "description de la page",
        group: "citySeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.thematic.title": {
        id: "seoConfig.thematic.title",
        label: "titre de la page",
        group: "thematicSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.thematic.desc": {
        id: "seoConfig.thematic.desc",
        label: "description de la page",
        group: "thematicSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.content.title": {
        id: "seoConfig.content.title",
        label: "titre de la page",
        group: "contentSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.content.desc": {
        id: "seoConfig.content.desc",
        label: "description de la page",
        group: "contentSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.booking.title": {
        id: "seoConfig.booking.title",
        label: "titre de la page",
        group: "bookingSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.booking.desc": {
        id: "seoConfig.booking.desc",
        label: "description de la page",
        group: "bookingSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.account.title": {
        id: "seoConfig.account.title",
        label: "titre de la page",
        group: "accountSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.account.desc": {
        id: "seoConfig.account.desc",
        label: "description de la page",
        group: "accountSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.room.title": {
        id: "seoConfig.room.title",
        label: "titre de la page",
        group: "roomSeo",
        componentOptions: {
          type: "Input",
        },
      },
      "seoConfig.room.desc": {
        id: "seoConfig.room.desc",
        label: "description de la page",
        group: "roomSeo",
        componentOptions: {
          type: "Input",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_CONTENT_SECTION]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_CONTENT_SECTION,
    label: "emplacement",
    labelWithPreposition: "de l’emplacement",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      title: {
        id: "title",
        label: "titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      subtitle: {
        id: "subtitle",
        label: "sous-titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_HOTEL]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_HOTEL,
    label: "hôtel",
    labelWithPreposition: "de l’hôtel",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "description de l’hébergement",
      },
      address: {
        id: "address",
        label: "localisation de l'hébergement",
      },
    },
    translatableAttributes: {
      title: {
        id: "title",
        label: "titre de l’annonce",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      about: {
        id: "about",
        label: "description du bien",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      infos: {
        id: "infos",
        label: "informations sur le bien",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      handicap: {
        id: "handicap",
        label: "informations sur les aménagements handicap",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      welcome: {
        id: "welcome",
        label: "informations d'entrées dans le logement",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      availabilities: {
        id: "availabilities",
        label: "informations sur les périodes d'ouverture",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      more: {
        id: "more",
        label: "autres informations (gîte plus)",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      access: {
        id: "access",
        label: "plan accès",
        group: "address",
        componentOptions: {
          type: "Textarea",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_ROOM]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_ROOM,
    label: "chambre",
    labelWithPreposition: "de la chambre",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      about: {
        id: "about",
        label: "description",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      infos: {
        id: "infos",
        label: "informations",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      handicap: {
        id: "handicap",
        label: "informations sur les aménagements handicap",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      availabilities: {
        id: "availabilities",
        label: "informations sur les périodes d'ouverture",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      more: {
        id: "more",
        label: "autres informations (gîte plus)",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_SERVICE]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_SERVICE,
    label: "service supplémentaire d’hébergement",
    labelWithPreposition: "du service supplémentaire d’hébergement",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      name: {
        id: "name",
        label: "nom",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      description: {
        id: "description",
        label: "description",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_FEATURE]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_FEATURE,
    label: "fonctionnalité d’hébergement",
    labelWithPreposition: "de la fonctionnalité d’hébergement",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      name: {
        id: "name",
        label: "nom",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      about: {
        id: "about",
        label: "description",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_PICTURE]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_HOTEL_PICTURE,
    label: "photo d’hébergement",
    labelWithPreposition: "de la photo d’hébergement",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      caption: {
        id: "caption",
        label: "légende",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_SLIDE]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_SLIDE,
    label: "diaporama",
    labelWithPreposition: "du diaporama",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      title: {
        id: "title",
        label: "titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      subtitle: {
        id: "subtitle",
        label: "sous-titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      target: {
        id: "target",
        label: "lien",
        group: "info",
        componentOptions: {
          type: "Input",
          props: {
            placeholder: "https://",
            inputProps: {
              type: "url",
            },
          },
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_CHANNEL_INSURANCE]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_CHANNEL_INSURANCE,
    label: "assurance",
    labelWithPreposition: "de l’assurance",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      name: {
        id: "name",
        label: "nom",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      marketingInfos: {
        id: "marketingInfos",
        label: "description",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      insurerInfos: {
        id: "insurerInfos",
        label: "Informations sur l’assureur",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
      cancellationInfos: {
        id: "cancellationInfos",
        label: "Procédure d’annulation",
        group: "info",
        componentOptions: {
          type: "Textarea",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_CONTENT]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_CONTENT,
    label: "page administrable",
    labelWithPreposition: "de la page administrable",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations sur la page",
      },
      content: {
        id: "content",
        label: "contenu de la page",
      },
      seo: {
        id: "seo",
        label: "référencement de la page",
      },
    },
    translatableAttributes: {
      title: {
        id: "title",
        label: "titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      subtitle: {
        id: "subtitle",
        label: "sous-titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      otherTitle: {
        id: "otherTitle",
        label: "titre vignette",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      otherSubtitle: {
        id: "otherSubtitle",
        label: "sous-titre vignette",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      about: {
        id: "about",
        label: "description",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      seoTitle: {
        id: "seoTitle",
        label: "titre SEO",
        group: "seo",
        componentOptions: {
          type: "Input",
        },
      },
      seoDesc: {
        id: "seoDesc",
        label: "description SEO",
        group: "seo",
        componentOptions: {
          type: "Input",
        },
      },
      content: {
        id: "content",
        label: "contenu",
        group: "content",
        componentOptions: {
          type: "LegacyEditor",
          props: {
            editorInit: {
              plugins: [
                "paste",
                "link",
                "autoresize",
                "image",
                "imagetools",
                "fullscreen",
                "visualblocks",
                "table",
                "code",
              ],
            },
          },
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_CUSTOMER]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_CUSTOMER,
    label: "client",
    labelWithPreposition: "du client",
    translatableAttributeGroups: {
      owner: {
        id: "owner",
        label: "activation du mode propriétaire pour ce compte",
      },
    },
    translatableAttributes: {
      about: {
        id: "about",
        label: "description publique",
        group: "owner",
        componentOptions: {
          type: "Textarea",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_POST]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_POST,
    label: "publication",
    labelWithPreposition: "de la publication",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      title: {
        id: "title",
        label: "titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
      content: {
        id: "content",
        label: "contenu",
        group: "info",
        componentOptions: {
          type: "Editor",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_POST_CATEGORY]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_POST_CATEGORY,
    label: "catégorie de publication",
    labelWithPreposition: "de la catégorie de publication",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      title: {
        id: "title",
        label: "titre",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
    },
  },
  [TRANSLATION_OBJECT_TYPE_VALUE_COVER]: {
    id: TRANSLATION_OBJECT_TYPE_VALUE_COVER,
    label: "photo de couverture",
    labelWithPreposition: "de la photo de couverture",
    translatableAttributeGroups: {
      info: {
        id: "info",
        label: "informations générales",
      },
    },
    translatableAttributes: {
      caption: {
        id: "caption",
        label: "légende",
        group: "info",
        componentOptions: {
          type: "Input",
        },
      },
    },
  },
};

export const TRANSLATION_OBJECT_TYPE_LIST = Object.values(
  TRANSLATION_OBJECT_TYPES,
);

// Langues supportées

/** Anglais */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_ENGLISH = "en";
/** Allemand */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_GERMAN = "de";
/** Italien */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_ITALIAN = "it";
/** Espagnol */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_SPANISH = "es";
/** Portugais */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_PORTUGUESE = "pt";
/** Polonais */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_POLISH = "pl";
/** Néérlandais */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_DUTCH = "nl";
/** Roumain */
export const TRANSLATION_SUPPORTED_LOCALE_VALUE_ROMANIAN = "ro";

/**
 * @typedef {"en" | "de" | "it" | "es" | "pt" | "pl" | "nl" | "ro"} TranslationSupportedLocaleKey
 */

/**
 * @typedef {"fr"} TranslationSourceLocaleKey
 */

/**
 * @typedef {"fr" | "en" | "de" | "it" | "es" | "pt" | "pl" | "nl" | "ro"} TranslationAppLocaleKey
 */

/**
 * @template TKey
 * @typedef {object} TranslationLocale
 * @prop {TKey} id
 * @prop {string} label
 * @prop {string} nativeLabel
 * @prop {string} flag
 * @prop {string} isoCode
 * @prop {string} logoPath
 */

/** @type {Record<TranslationSupportedLocaleKey, TranslationLocale<TranslationSupportedLocaleKey>>} */
export const TRANSLATION_SUPPORTED_LOCALES = {
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_ENGLISH]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_ENGLISH,
    label: "anglais",
    nativeLabel: "english",
    flag: "🇬🇧",
    isoCode: "GB",
    logoPath: "/assets/countries/flags/gb.svg",
  },
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_GERMAN]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_GERMAN,
    label: "allemand",
    nativeLabel: "deutsch",
    flag: "🇩🇪",
    isoCode: "DE",
    logoPath: "/assets/countries/flags/de.svg",
  },
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_ITALIAN]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_ITALIAN,
    label: "italien",
    nativeLabel: "italiano",
    flag: "🇮🇹",
    isoCode: "IT",
    logoPath: "/assets/countries/flags/it.svg",
  },
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_SPANISH]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_SPANISH,
    label: "espagnol",
    nativeLabel: "español",
    flag: "🇪🇸",
    isoCode: "ES",
    logoPath: "/assets/countries/flags/es.svg",
  },
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_PORTUGUESE]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_PORTUGUESE,
    label: "portugais",
    nativeLabel: "português",
    flag: "🇵🇹",
    isoCode: "PT",
    logoPath: "/assets/countries/flags/pt.svg",
  },
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_POLISH]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_POLISH,
    label: "polonais",
    nativeLabel: "polski",
    flag: "🇵🇱",
    isoCode: "PL",
    logoPath: "/assets/countries/flags/pl.svg",
  },
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_DUTCH]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_DUTCH,
    label: "néérlandais",
    nativeLabel: "nederlands",
    flag: "🇳🇱",
    isoCode: "NL",
    logoPath: "/assets/countries/flags/nl.svg",
  },
  [TRANSLATION_SUPPORTED_LOCALE_VALUE_ROMANIAN]: {
    id: TRANSLATION_SUPPORTED_LOCALE_VALUE_ROMANIAN,
    label: "roumain",
    nativeLabel: "română",
    flag: "🇷🇴",
    isoCode: "RO",
    logoPath: "/assets/countries/flags/ro.svg",
  },
};

export const TRANSLATION_SUPPORTED_LOCALE_LIST = Object.values(
  TRANSLATION_SUPPORTED_LOCALES,
);

// Langue source

/** @type {TranslationLocale<TranslationSourceLocaleKey>} */
export const TRANSLATION_SOURCE_LOCALE = {
  id: "fr",
  label: "français",
  nativeLabel: "français",
  flag: "🇫🇷",
  isoCode: "FR",
  logoPath: "/assets/countries/flags/fr.svg",
};

/** @type {Record<TranslationAppLocaleKey, TranslationLocale<TranslationAppLocaleKey>>} */
export const TRANSLATION_APP_LOCALES = {
  ...TRANSLATION_SUPPORTED_LOCALES,
  fr: TRANSLATION_SOURCE_LOCALE,
};

export const TRANSLATION_APP_LOCALES_LIST = Object.values(
  TRANSLATION_APP_LOCALES,
);
export const TRANSLATION_APP_LOCALES_VALUES = TRANSLATION_APP_LOCALES_LIST.map(
  (locale) => locale.id,
);
