// Source de l’apport

/** Porteur d’affaire client */
export const CHANNEL_RATE_VALUE_CUSTOMER = "customer";
/** Porteur d’affaire propriétaire */
export const CHANNEL_RATE_VALUE_SYSTEM = "system";

export const CHANNEL_RATES = {
  [CHANNEL_RATE_VALUE_CUSTOMER]: {
    id: CHANNEL_RATE_VALUE_CUSTOMER,
    label: "Apport propriétaire",
  },
  [CHANNEL_RATE_VALUE_SYSTEM]: {
    id: CHANNEL_RATE_VALUE_SYSTEM,
    label: "Apport système",
  },
};

export const CHANNEL_RATE_LIST = Object.values(CHANNEL_RATES);

// Source de contact sur la fiche hébergement

export const CHANNEL_ROOM_CONTACT_SOURCE_VALUE_CLOUDSPIRE = "cloudspire";
export const CHANNEL_ROOM_CONTACT_SOURCE_VALUE_OTHERS = "others";

export const CHANNEL_ROOM_CONTACT_SOURCES = {
  [CHANNEL_ROOM_CONTACT_SOURCE_VALUE_CLOUDSPIRE]: {
    id: CHANNEL_ROOM_CONTACT_SOURCE_VALUE_CLOUDSPIRE,
    label: "Site en marque blanche (CS)",
  },
  [CHANNEL_ROOM_CONTACT_SOURCE_VALUE_OTHERS]: {
    id: CHANNEL_ROOM_CONTACT_SOURCE_VALUE_OTHERS,
    label: "Autres sites (Site FNGDF, ReservIT, ...)",
  },
};

export const CHANNEL_ROOM_CONTACT_SOURCE_LIST = Object.values(
  CHANNEL_ROOM_CONTACT_SOURCES,
);

// Type d’information de contact affiché sur la fiche hébergement

export const CHANNEL_ROOM_CONTACT_INFOS_TYPE_VALUE_CHANNEL = "channel";
export const CHANNEL_ROOM_CONTACT_INFOS_TYPE_VALUE_OWNER = "owner";

export const CHANNEL_ROOM_CONTACT_INFOS_TYPES = {
  [CHANNEL_ROOM_CONTACT_INFOS_TYPE_VALUE_CHANNEL]: {
    id: CHANNEL_ROOM_CONTACT_INFOS_TYPE_VALUE_CHANNEL,
    label: "Centrale",
  },
  [CHANNEL_ROOM_CONTACT_INFOS_TYPE_VALUE_OWNER]: {
    id: CHANNEL_ROOM_CONTACT_INFOS_TYPE_VALUE_OWNER,
    label: "Propriétaire",
  },
};

export const CHANNEL_ROOM_CONTACT_INFOS_TYPE_LIST = Object.values(
  CHANNEL_ROOM_CONTACT_INFOS_TYPES,
);

// Réseaux sociaux

/** Facebook */
export const CHANNEL_SOCIAL_VALUE_FACEBOOK = "fb";
/** Twitter */
export const CHANNEL_SOCIAL_VALUE_TWITTER = "tw";
/** Google+ */
export const CHANNEL_SOCIAL_VALUE_GOOGLE_PLUS = "go";
/** Pinterest */
export const CHANNEL_SOCIAL_VALUE_PINTEREST = "pi";
/** Instagram */
export const CHANNEL_SOCIAL_VALUE_INSTAGRAM = "in";
/** Tumblr */
export const CHANNEL_SOCIAL_VALUE_TUMBLR = "th";
/** Linkedin */
export const CHANNEL_SOCIAL_VALUE_LINKEDIN = "li";
/** Viadeo */
export const CHANNEL_SOCIAL_VALUE_VIADEO = "vi";
/** Youtube */
export const CHANNEL_SOCIAL_VALUE_YOUTUBE = "yo";
/** Tiktok */
export const CHANNEL_SOCIAL_VALUE_TIKTOK = "ti";

export const CHANNEL_SOCIALS = {
  [CHANNEL_SOCIAL_VALUE_FACEBOOK]: {
    id: CHANNEL_SOCIAL_VALUE_FACEBOOK,
    enabled: true,
    label: "Facebook",
    color: "#3b5998",
    frontIcon: "IconFacebook",
  },
  [CHANNEL_SOCIAL_VALUE_TWITTER]: {
    id: CHANNEL_SOCIAL_VALUE_TWITTER,
    enabled: true,
    label: "Twitter",
    color: "#1da1f2",
    frontIcon: "IconTwitter",
  },
  [CHANNEL_SOCIAL_VALUE_GOOGLE_PLUS]: {
    id: CHANNEL_SOCIAL_VALUE_GOOGLE_PLUS,
    enabled: false,
    label: "Google",
    color: "#dd4b39",
    frontIcon: "IconGoogle",
  },
  [CHANNEL_SOCIAL_VALUE_PINTEREST]: {
    id: CHANNEL_SOCIAL_VALUE_PINTEREST,
    enabled: true,
    label: "Pinterest",
    color: "#bd081c",
    frontIcon: "IconPinterest",
  },
  [CHANNEL_SOCIAL_VALUE_INSTAGRAM]: {
    id: CHANNEL_SOCIAL_VALUE_INSTAGRAM,
    enabled: true,
    label: "Instagram",
    color: "#e1306c",
    frontIcon: "IconInstagram",
  },
  [CHANNEL_SOCIAL_VALUE_TUMBLR]: {
    id: CHANNEL_SOCIAL_VALUE_TUMBLR,
    enabled: true,
    label: "Tumblr",
    color: "#35465d",
    frontIcon: "IconTumblr",
  },
  [CHANNEL_SOCIAL_VALUE_LINKEDIN]: {
    id: CHANNEL_SOCIAL_VALUE_LINKEDIN,
    enabled: true,
    label: "Linkedin",
    color: "#0077b5",
    frontIcon: "IconLinkedin",
  },
  [CHANNEL_SOCIAL_VALUE_VIADEO]: {
    id: CHANNEL_SOCIAL_VALUE_VIADEO,
    enabled: true,
    label: "Viadeo",
    color: "#f07355",
    frontIcon: "IconViadeo",
  },
  [CHANNEL_SOCIAL_VALUE_YOUTUBE]: {
    id: CHANNEL_SOCIAL_VALUE_YOUTUBE,
    enabled: true,
    label: "Youtube",
    color: "#fe0000",
    frontIcon: "IconYoutube",
  },
  [CHANNEL_SOCIAL_VALUE_TIKTOK]: {
    id: CHANNEL_SOCIAL_VALUE_TIKTOK,
    enabled: true,
    label: "Tiktok",
    color: "#000000",
    frontIcon: "IconTiktok",
  },
};

export const CHANNEL_SOCIAL_LIST = Object.values(CHANNEL_SOCIALS);

// Action à faire en cas de non réponse

/** Refus */
export const CHANNEL_OACK_ACTION_VALUE_ABORT = "reject";
/** Acceptation via paiement */
export const CHANNEL_OACK_ACTION_VALUE_OPEN = "accept";

export const CHANNEL_OACK_ACTIONS = {
  [CHANNEL_OACK_ACTION_VALUE_ABORT]: {
    id: CHANNEL_OACK_ACTION_VALUE_ABORT,
    label: "refuser",
    label2: "abandonner",
  },
  [CHANNEL_OACK_ACTION_VALUE_OPEN]: {
    id: CHANNEL_OACK_ACTION_VALUE_OPEN,
    label: "accepter",
    label2: "valider via paiement",
  },
};

export const CHANNEL_OACK_ACTION_LIST = Object.values(CHANNEL_OACK_ACTIONS);

// Thèmes visuels

/** Cloudspire */
export const CHANNEL_THEME_VALUE_CLOUDSPIRE = "cloudspire";
/** Gîtes de France® */
export const CHANNEL_THEME_VALUE_GDF = "gdf";
/** Nattitude */
export const CHANNEL_THEME_VALUE_NATTITUDE = "natti";
/** France Traditions */
export const CHANNEL_THEME_VALUE_FRTRAD = "frtrad";
/** Clévacance */
export const CHANNEL_THEME_VALUE_CLEVACANCES = "clev";
/** Gers Réservation */
export const CHANNEL_THEME_VALUE_GERS_RESERVATION = "gersres";
/** Ferme Auberge */
export const CHANNEL_THEME_VALUE_FERME_AUBERGE = "fera";
/** Maison du Tourisme (Haute-Marne) */
export const CHANNEL_THEME_VALUE_MDT52 = "mdt52";
/** Gîtes du Luxembourg */
export const CHANNEL_THEME_VALUE_GITES_DU_LUXEMBOURG = "gitdulux";
/** Evasion Location */
export const CHANNEL_THEME_VALUE_EVASION_LOCATION = "evaloc";
/** Agence de Développement Touristique */
export const CHANNEL_THEME_VALUE_ADT = "adt";

/** @type {Record<import("../types/alfred/Channel").ChannelThemeName, { id: import("../types/alfred/Channel").ChannelThemeName; label: string; }>} */
export const CHANNEL_THEMES = {
  [CHANNEL_THEME_VALUE_CLOUDSPIRE]: {
    id: CHANNEL_THEME_VALUE_CLOUDSPIRE,
    label: "Cloudspire",
  },
  [CHANNEL_THEME_VALUE_GDF]: {
    id: CHANNEL_THEME_VALUE_GDF,
    label: "Gîtes de France®",
  },
  [CHANNEL_THEME_VALUE_NATTITUDE]: {
    id: CHANNEL_THEME_VALUE_NATTITUDE,
    label: "Nattitude",
  },
  [CHANNEL_THEME_VALUE_FRTRAD]: {
    id: CHANNEL_THEME_VALUE_FRTRAD,
    label: "France Traditions",
  },
  [CHANNEL_THEME_VALUE_CLEVACANCES]: {
    id: CHANNEL_THEME_VALUE_CLEVACANCES,
    label: "Clévacances",
  },
  [CHANNEL_THEME_VALUE_GERS_RESERVATION]: {
    id: CHANNEL_THEME_VALUE_GERS_RESERVATION,
    label: "Gers Réservation",
  },
  [CHANNEL_THEME_VALUE_FERME_AUBERGE]: {
    id: CHANNEL_THEME_VALUE_FERME_AUBERGE,
    label: "Ferme Auberge",
  },
  [CHANNEL_THEME_VALUE_MDT52]: {
    id: CHANNEL_THEME_VALUE_MDT52,
    label: "Maison du Tourisme (Haute-Marne)",
  },
  [CHANNEL_THEME_VALUE_GITES_DU_LUXEMBOURG]: {
    id: CHANNEL_THEME_VALUE_GITES_DU_LUXEMBOURG,
    label: "Gîtes du Luxembourg",
  },
  [CHANNEL_THEME_VALUE_EVASION_LOCATION]: {
    id: CHANNEL_THEME_VALUE_EVASION_LOCATION,
    label: "Evasion Location",
  },
  [CHANNEL_THEME_VALUE_ADT]: {
    id: CHANNEL_THEME_VALUE_ADT,
    label: "Agence de Développement Touristique",
  },
};

export const CHANNEL_THEME_LIST = Object.values(CHANNEL_THEMES);
